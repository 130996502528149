import React from "react";
// import logo from "./logo.svg";
import "./App.css";
// import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;
const axios = require("axios");
// console.log(baseURL);
//fetch data from the server

function state(state) {
  if (!state) {
    return (
      <div className="inline-block ">
        <p className="bg-red-500 rounded p-1 text-white font-bold">
          not running
        </p>
      </div>
    );
  } else {
    return (
      <div className="inline-block">
        <p className="bg-green-500 rounded p-1 text-white font-bold">running</p>
      </div>
    );
  }
}
function runScript(name, thiss) {
  if (
    thiss.state.agroState === false &&
    thiss.state.alphaState === false &&
    thiss.state.panziState === false &&
    thiss.state.honeypetState === false
  ) {
    return (
      <div className="inline-block">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
          onClick={() => {
            axios.get(`${baseURL}/` + name, {}).then((res) => {
              alert(res.data.message);
              thiss.fetchStates();
            });
          }}
        >
          Run
        </button>
      </div>
    );
  } else {
    return;
  }
}

function runAll(thiss, agroState, alphaState, panziState, honeypetState) {
  if (
    agroState === false &&
    alphaState === false &&
    panziState === false &&
    honeypetState === false
  ) {
    // console.log(agroState, alphaState, panziState, honeypetState);
    return (
      <div className="inline-block mx-4">
        <button
          className="bg-pink-500 hover:bg-pink-700 transition-all text-white font-bold py-1 px-4 rounded"
          onClick={() => {
            axios.get(`${baseURL}/runall`, {}).then((res) => {
              alert(res.data.message);
              thiss.fetchStates();
            });
          }}
        >
          Run All
        </button>
      </div>
    );
  } else {
    return;
  }
}

function Buttons(thiss) {
  if (thiss.state.currentPage === 1) {
    return (
      <div className="m-4 block">
        <button
          className="bg-blue-500 hover:bg-blue-700 m-4 float-right text-white font-bold py-1 px-4 rounded"
          onClick={() => {
            thiss.setState({ currentPage: 2 });
            thiss.fetchPage(thiss.state.currentPage);
          }}
        >
          Next
        </button>
      </div>
    );
  } else if (thiss.state.currentPage === thiss.state.maxPages) {
    return (
      <div className="m-4 block">
        <button
          className="bg-blue-500 m-4 float-left  hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
          onClick={() => {
            thiss.setState({ currentPage: thiss.state.currentPage - 1 });
            thiss.fetchPage(thiss.state.currentPage);
          }}
        >
          Prev
        </button>
      </div>
    );
  } else {
    return (
      <div className="m-4 block">
        <button
          className="bg-blue-500 m-4 hover:bg-blue-700 text-white float-left font-bold py-1 px-4 rounded"
          onClick={() => {
            thiss.setState({ currentPage: thiss.state.currentPage - 1 });
            thiss.fetchPage(thiss.state.currentPage);
          }}
        >
          Prev
        </button>
        <button
          className="bg-blue-500 m-4 hover:bg-blue-700 text-white float-right font-bold py-1 px-4 rounded"
          onClick={() => {
            thiss.setState({ currentPage: thiss.state.currentPage + 1 });
            thiss.fetchPage(thiss.state.currentPage);
            // console.log(thiss.state.currentPage, thiss.state.maxPages)
          }}
        >
          Next
        </button>
      </div>
    );
  }
}
class App extends React.Component {
  // Constructor
  constructor(props) {
    super(props);

    this.state = {
      DataisLoaded: false,
      agroState: false,
      alphaState: false,
      honeypetState: false,
      panziState: false,
    };
  }
  async fetchPage(page) {
    await axios.get(`${baseURL}/products/` + page, {}).then((res) => {
      // console.log(res.data);
      this.setState({
        products: res.data,
      });
    });
  }
  // ComponentDidMount is used to
  // execute the code
  fetchStates() {
    fetch(baseURL + "/agro/state")
      .then((res) => res.json())
      .then((json) => {
        // console.log(json);
        let agroState = json.state;
        fetch(baseURL + "/alpha/state")
          .then((res) => res.json())
          .then((json) => {
            // console.log(json);
            let alphaState = json.state;
            fetch(baseURL + "/honeypet/state")
              .then((res) => res.json())
              .then((json) => {
                // console.log(json);
                let honeypetState = json.state;
                fetch(baseURL + "/panzi/state")
                  .then((res) => res.json())
                  .then((json) => {
                    // console.log(json);
                    this.setState({
                      panziState: json.state,
                      agroState: agroState,
                      alphaState: alphaState,
                      honeypetState: honeypetState,
                    });
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  fetchInterval() {
    axios.get(`${baseURL}/interval`, {}).then((res) => {
      console.log(res.data);
      this.setState({
        interval: res.data.interval,
        lastRunTime: res.data.lastUpdate,
      });
    });
  }
  async componentDidMount() {
    this.setState({
      isLoggedIn: localStorage.getItem("isLoggedIn") || false,
    });
    this.fetchStates();
    // fetch states every 5 seconds
    await fetch(baseURL + "/maxPages")
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          maxPages: json,
          currentPage: 1,
        });
        // console.log(json);
      });
    await this.fetchPage(this.currentPage);
    await this.fetchInterval();
    this.setState({
      DataisLoaded: true,
    });

    // console.log(this.state.products);
    setInterval(() => {
      this.fetchStates();
    }, 5000);
  }
  handleDownload() {
    const url = baseURL + "/export";
    const filename = "export.xlsx";
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  render() {
    const {
      DataisLoaded,
      alphaState,
      agroState,
      panziState,
      honeypetState,
      isLoggedIn,
    } = this.state;
    if (!isLoggedIn) {
      return (
        <div class="flex items-center justify-center min-h-screen bg-gray-100">
          <div class="px-16 py-6 mt-4 text-left bg-white shadow-lg">
            <h3 class="text-2xl font-bold text-center">
              Bejelentkezés
            </h3>
            <form>
              <div class="mt-4">
                <div>
                  <label class="block" for="email">
                    Felhasználónév
                  </label>
                  <input
                    type="text"
                    placeholder="Felhasználónév..."
                    id="email"
                    class="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-green-600"
                  />
                </div>
                <div class="mt-4">
                  <label class="block">Jelszó</label>
                  <input
                    type="password"
                    id="password"
                    placeholder="Jelszó..."
                    class="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-green-600"
                  />
                </div>
                <div class="flex items-baseline justify-between">
                  <button class="px-6 py-2 mt-4 text-white bg-green-600 rounded-lg hover:bg-green-900 transition-all duration-200" onClick={
                    () => {
                      if (document.getElementById("email").value === "all4pets" && document.getElementById("password").value === "4ll4P3tsSh0p") {
                        this.setState({
                          isLoggedIn: true,
                        });
                        localStorage.setItem("isLoggedIn", true);
                      }
                      else {
                        alert("Hibás felhasználónév vagy jelszó!");
                      }
                    }
                  }>
                    Bejelentkezés
                  </button>
                  
                </div>
              </div>
            </form>
          </div>
        </div>
      );
    }
    if (!DataisLoaded)
      return (
        <div>
          <h1> Please wait some time.... </h1>{" "}
        </div>
      );
    return (
      <div className="App">
        <h1 className="text-3xl font-bold"> Containers: </h1>{" "}
        <div className="p-4">
          <input type="file" name="table"></input>
          <button
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-3 rounded  transition-all duration-500"
            onClick={() => {
              //get file from table input
              const file = document.querySelector("input[name='table']")
                .files[0];
              if (file === undefined) {
                return;
              }
              //post file to the server
              const formData = new FormData();
              formData.append("table", file);
              axios
                .post(`${baseURL}/upload`, formData)
                .then((res) => {
                  alert(res.data.message);
                  //clear the input
                  document.querySelector("input[name='table']").value = "";
                })
                .catch((err) => {
                  console.log(err);
                  alert("Error: use xlsx file");
                  document.querySelector("input[name='table']").value = "";
                });
            }}
          >
            Upload
          </button>
          <input type="file" name="tabledel"></input>
          <button
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-3 rounded  transition-all duration-500"
            onClick={() => {
              //get file from table input
              const file = document.querySelector("input[name='tabledel']")
                .files[0];
              if (file === undefined) {
                return;
              }
              //post file to the server
              const formData = new FormData();
              formData.append("table", file);
              axios
                .post(`${baseURL}/uploadDel`, formData)
                .then((res) => {
                  alert(res.data.message);
                  //clear the input
                  document.querySelector("input[name='tabledel']").value = "";
                })
                .catch((err) => {
                  console.log(err);
                  alert("Error: use xlsx file");
                  document.querySelector("input[name='tabledel']").value = "";
                });
            }}
          >
            Upload Delete
          </button>
          <button
            className="bg-blue-500 m-4 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded inline-block"
            onClick={this.handleDownload}
          >
            Export
          </button>
        </div>
        <h1 className="inline-block m-2">Agro State: </h1> {state(agroState)}{" "}
        {runScript("agro", this)}
        <h1 className="inline-block m-2">Alpha State: </h1> {state(alphaState)}{" "}
        {runScript("alpha", this)}
        <h1 className="inline-block m-2">HoneyPet State:</h1>{" "}
        {state(honeypetState)} {runScript("honeypet", this)}
        <h1 className="inline-block m-2">Panzi State: </h1> {state(panziState)}{" "}
        {runScript("panzi", this)}
        {runAll(this, agroState, alphaState, panziState, honeypetState)}
        {Buttons(this)}
        <div className="p-4">
          <h3 className="text-3xl font-bold inline-block align-bottom">
            {" "}
            Interval:{" "}
          </h3>
          <input
            type="number"
            className="bg-gray-200 appearance-none border-2 border-gray-200 mx-3 rounded w-12 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            placeholder="Enter interval in seconds"
            defaultValue={this.state.interval}
          />
          <button
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-3 rounded  transition-all duration-500"
            onClick={() => {
              //get file from table input
              const interval = document.querySelector(
                "input[type='number']"
              ).value;
              axios
                .post(`${baseURL}/interval`, { interval: interval })
                .then((res) => {
                  alert(res.data.message);
                });
            }}
          >
            Set
          </button>
          <p>
            Last Run Time:{" "}
            {String(this.state.lastRunTime).split("T")[0] +
              " " +
              String(String(this.state.lastRunTime).split("T")[1]).slice(0, 5)}
          </p>
        </div>
        <p className="text-center">
          {this.state.currentPage}/{this.state.maxPages}
        </p>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mx-36 mt-4 mb-4">
          <table className="w-full text-sm  text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="px-4 py-2 text-left">Code</th>
                <th className="px-4 py-2 text-left">Manufacturer Code</th>
                <th className="px-4 py-2 text-left">Name</th>
                <th className="px-4 py-2 text-center">Multiplier</th>
                <th className="px-4 py-2 text-center">Original Price</th>
                <th className="px-4 py-2 text-center">Updated Price</th>
                <th className="px-4 py-2 text-center">Status</th>
                <th className="px-4 py-2 text-center">Update</th>
              </tr>
            </thead>
            <tbody>
              {this.state.products.map((product) => {
                return (
                  <tr key={product._id}>
                    <td className="px-4 py-2 text-left">{product.code}</td>{" "}
                    <td className="px-4 py-2 text-left">
                      {product.manufacturerCode}
                    </td>
                    <td className="px-4 py-2 text-left">{product.name}</td>
                    <td className="px-4 py-2 text-center">
                      {" "}
                      <input
                        className="px-4 py-2 w-14 text-center border-2 border-gray-200 dark:border-gray-700 rounded bg-gray-50 "
                        defaultValue={
                          /* Round up multiplier */ parseFloat(
                            product.multiplier
                          )
                        }
                        name={product.manufacturerCode}
                      />
                    </td>
                    <td className="px-4 py-2 text-centre">{product.price}</td>
                    <td className="px-4 py-2 text-centre">
                      <input
                        name={product._id + "-multiplier"}
                        className={
                          product._id +
                          "-multiplier, text-center p-2 w-16 border-2 border-gray-200 dark:border-gray-700 rounded bg-gray-50"
                        }
                        defaultValue={parseFloat(
                          product.price * product.multiplier
                        ).toFixed(0)}
                        onKeyDown={(e) => {
                          //update the multiplier

                          document.querySelector(
                            `input[name='${product.manufacturerCode}']`
                          ).value = parseFloat(
                            (document.querySelector(
                              `input[name='${product._id + "-multiplier"}']`
                            ).value /
                              product.price) *
                              10
                          );
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        defaultChecked={product.status}
                        name={product._id + "-status"}
                      />
                    </td>
                    <td className="px-4 py-2 text-center">
                      <input
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-3 rounded  transition-all duration-500 text-center "
                        value="Update"
                        type="button"
                        onClick={
                          /* Update multipiler by code */
                          () => {
                            axios
                              .patch(
                                `${baseURL}/product/${product.manufacturerCode}`,
                                {
                                  multiplier: parseFloat(
                                    document.querySelector(
                                      `input[name='${product.manufacturerCode}']`
                                    ).value
                                  ),
                                  code: product.code,
                                  supplier: product.supplier,
                                  name: product.name,
                                  manufacturerCode: product.manufacturerCode,
                                  status: document.querySelector(
                                    `input[name='${product._id + "-status"}']`
                                  ).checked,
                                  price: product.price,
                                  stock: product.stock,
                                  type: product.type,
                                  isDiscounted: product.isDiscounted,
                                }
                              )
                              .then((res) => {
                                alert(res.data.message);
                                //force update the multiplied price
                                console.log(
                                  document.querySelector(
                                    `input[name='${
                                      product._id + "-multiplier"
                                    }']`
                                  ).value
                                );
                                document.querySelector(
                                  `input[name='${product._id + "-multiplier"}']`
                                ).value = parseFloat(
                                  product.price *
                                    parseFloat(
                                      document.querySelector(
                                        `input[name='${product.manufacturerCode}']`
                                      ).value
                                    )
                                ).toFixed(0);
                              });
                          }
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {Buttons(this)}
      </div>
    );
  }
}

export default App;
